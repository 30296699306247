import { useState, useEffect, useRef } from 'react';
import { parseISO, format } from 'date-fns';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import { useDataState } from '@consumers/index';
import { useSettingsState } from '@consumers/index';
import { feeChartTransitions as transitions } from '@src/constants';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const FeeChart = ({ width }: IChartComponentProps) => {
  const chartRef = useRef(null);
  const { baseFeeRecordArr } = useDataState() as DataContextProps;
  const { d } = useSettingsState() as SettingsContextProps;
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  });

  const options: any = {
    responsive: true,
    transitions,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: 'Historical Base Fee',
        color: d ? 'rgba(70, 144, 222)' : 'rgba(29, 93, 210, 0.9)',
        font: {
          size: 30,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          weight: 300,
        },
      },
      tooltip: {
        callbacks: {
          title: function (value: any) {
            const { dataIndex, raw } = value[0];
            return `Block ${baseFeeRecordArr[dataIndex].blockNumber}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time (24 Hr Format)',
          color: d ? '#54648d' : 'rgba(29, 93, 210, 0.9)',
          font: {
            size: 20,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            weight: d ? 300 : 400,
            lineHeight: 1.2,
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
        grid: {
          color: d ? 'rgba(255, 255, 255, 0.15)' : 'rgba(75, 75, 75, 0.214)',
          lineWidth: 0.5,
        },
        ticks: {
          callback: function (obj: any): any {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const { timestamp, number } = this.getLabelForValue(obj);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            return format(parseISO(timestamp), 'HH:mm');
          },
          color: d ? '#a9a9a9' : 'rgba(29, 93, 210, 0.9)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Base Fee',
          color: d ? '#54648d' : 'rgba(29, 93, 210, 0.9)',
          font: {
            size: 20,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            weight: d ? 300 : 400,
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 20 },
        },
        grid: {
          color: d ? 'rgba(255, 255, 255, 0.15)' : 'rgba(43, 43, 43, 0.149)',
          lineWidth: 0.5,
        },
        ticks: {
          callback: function (value: any) {
            return value;
          },
          color: d ? '#a9a9a9' : 'rgba(29, 93, 210, 0.9)',
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current as ChartJS | null;
    if (!chart) {
      return;
    }

    let gradient = chart.ctx.createLinearGradient(0, 0, 0, 1000);

    if (d) {
      gradient.addColorStop(0, 'rgba(58, 123, 213, 0.7)');
      gradient.addColorStop(1, 'rgba(87, 90, 115, 0.117)');
    } else {
      gradient.addColorStop(0, 'rgba(58, 123, 213, 0.7)');
      gradient.addColorStop(1, 'rgba(29, 49, 182, 0.117)');
    }

    setChartData({
      labels: baseFeeRecordArr.map((x) => ({
        timestamp: x.timestamp,
        number: x.blockNumber,
      })),
      datasets: [
        {
          label: 'Base Fee',
          data: baseFeeRecordArr.map((x) => x.basefee),
          fill: true,
          backgroundColor: gradient,
          tension: 0.2,
          showLine: true,
          borderWidth: 0,
          pointRadius: 2,
          pointBackgroundColor: d ? 'rgb(58, 123, 213, 0.4)' : 'rgba(29, 93, 210, 0.9)',
          pointHitRadius: 5,
          pointHoverRadius: 10,
        },
      ],
    });
  }, [d, baseFeeRecordArr]);

  return (
    <div style={{ width: width, backgroundColor: d ? '#09223C' : 'rgb(83 184 249)', marginTop: '30px' }}>
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  );
};

export default FeeChart;
