function fetchAndParseItem(key: string) {
  const item = localStorage.getItem(key);

  if (item) {
    return JSON.parse(item);
  }

  return false;
}

export { fetchAndParseItem };
