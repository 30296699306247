import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Filler } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';

import { useDataState } from '@consumers/index';
import { useSettingsState } from '@consumers/index';

ChartJS.register(ArcElement, Tooltip, Legend, Filler);

const LoadingWrapper = styled.div`
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #00000029;
  border-radius: 25px;
  margin-bottom: 40px;

  h1 {
    font-family: sans-serif;
    opacity: 0.4;
    font-size: 24px;
    width: 80%;
    color: #fff;
    font-weight: 300;
  }
`;

const EIP1559Pie = ({ width }: IChartComponentProps) => {
  const chartRef = useRef(null);
  const { block } = useDataState() as DataContextProps;

  const { d } = useSettingsState() as SettingsContextProps;
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  });

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: 'EIP1559 Transaction Distribution',
        color: d ? 'rgba(70, 144, 222)' : 'rgba(29, 93, 210, 0.9)',
        font: {
          size: 30,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          weight: 300,
        },
        padding: 50,
      },
      datalabels: {
        color: 'rgb(255, 255, 255, 0.6)',
        font: {
          size: 24,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
        formatter: function (value: any, context: any) {
          const total = context.dataset.data.reduce((acc: number, d: number) => acc + d, 0);
          return Math.round((value / total) * 100) + '%';
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current as ChartJS | null;
    if (!chart || !block) {
      return;
    }

    const data = [
      Math.floor(block.txCount * block!.analytics!.eip1559Ratio),
      block.txCount - Math.floor(block.txCount * block!.analytics!.eip1559Ratio),
    ];

    setChartData({
      labels: ['EIP1559 TX', 'Legacy TX'],
      datasets: [
        {
          data,
          backgroundColor: ['rgba(58, 123, 213, 0.7)', 'rgba(245, 33, 33, 0.7)'],
          borderColor: ['rgba(58, 123, 213, 0.7)', 'rgba(245, 33, 33, 0.7)'],
          borderWidth: 1,
        },
      ],
    });
  }, [d, block]);

  return (
    <div style={{ width: width, backgroundColor: d ? '#09223C' : 'rgb(83 184 249)', marginTop: '30px' }}>
      {block?.txCount === 0 ? (
        <LoadingWrapper>
          <h1>No transactions in this block for EIP1559 chart data</h1>
        </LoadingWrapper>
      ) : (
        <Pie plugins={[ChartDataLabels]} ref={chartRef} data={chartData} options={options} />
      )}
    </div>
  );
};

export default EIP1559Pie;
