import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: ${spinnerAnimation} 0.71s infinite linear;

  &:after {
    border-radius: 50%;
    width: 2em;
    height: 2em;
  }
`;

const Spinner = () => {
  return <SpinnerWrapper></SpinnerWrapper>;
};

export default Spinner;
