import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useExplorerState } from '@src/consumers';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    font-family: sans-serif;
    font-weight: 300;
  }
`;

const SectionWrapper = styled.section`
  background: rgb(30, 68, 107);
  padding: 10px 20px;
  border-radius: 15px;
  width: 80%;
`;

const MetricWrapper = styled.div`
  display: flex;

  p:first-of-type {
    background: ${(props: IThemeProps) => props.theme.blockAnalytics.metricWrapper.labelBG};
    color: ${(props: IThemeProps) => props.theme.blockAnalytics.metricWrapper.labelValue};
    padding: 15px 25px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 200px;
    text-align: center;
    margin: 5px 0px;
    font-family: sans-serif;
  }

  p:last-of-type {
    background: ${(props: IThemeProps) => props.theme.blockAnalytics.metricWrapper.amountBG};
    color: ${(props: IThemeProps) => props.theme.blockAnalytics.metricWrapper.amountValue};
    padding: 15px 25px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    min-width: 180px;
    text-align: center;
    margin: 5px 0;
    font-family: sans-serif;
  }
`;

const BlockAnalyticsPage = () => {
  const { number } = useParams();
  const { setSearchTerm, blockDetails } = useExplorerState() as ExplorerContextProps;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const initPage = (async () => {
      if (number && !blockDetails) {
        await setSearchTerm(number);
      }
    })();
  }, []);

  return (
    <>
      {blockDetails && (
        <PageWrapper>
          <h1>Block Number: {blockDetails.number}</h1>
          <SectionWrapper>
            <MetricWrapper>
              <p>Basefee</p>
              <p>{blockDetails.basefee.toFixed(1)} Gwei</p>
            </MetricWrapper>
            <MetricWrapper>
              <p>Transaction Count</p>
              <p>{blockDetails.txCount}</p>
            </MetricWrapper>
          </SectionWrapper>
        </PageWrapper>
      )}
    </>
  );
};

export default BlockAnalyticsPage;
