import React, { useReducer, useEffect } from 'react';

import DataContext from './dataContext';
import dataReducer from './dataReducer';

import { BASE_URL } from '@src/constants';
import { fetchAndParseItem } from '@src/utils/storage';
import { Gastly } from '@src/api/gastly';
import { calcFeeCap } from '@utils/fees';

// WS Logic Variables (until I figure out a better way this is the solution)
let blockStart = Date.now();
let blockEnd;
let sub = {} as ISub;
let prevPacketUTC: string;
let handlerControl: IHandlerControl = { block: [] };
let disconnected = false;

const DataState: React.FC = (props) => {
  const initialState = {
    gasPrices: {
      fast: 0,
      average: 0,
      slow: 0,
    },
    ethusd: 2500.0,
    ethzar: 61200.0,
    ethaud: 1234.0,
    ethbtc: 0.69,
    gasFees: {
      fast: 0,
      average: 0,
      slow: 0,
    },
    fiatFees: {
      fast: 0,
      average: 0,
      slow: 0,
    },
    transactionPreference: localStorage.getItem('transactionPreference') || 'eth-send',
    fiatPreference: localStorage.getItem('fiatPreference') || 'ethusd',
    speed: localStorage.getItem('gasPreference') || 'fast',
    block: null,
    priorityFees: { fast: 3, average: 2, slow: 1 },
    baseFeeRecordArr: [],
    txCountRecordArr: [],
    firstPaintLoad: true,
  };

  const [state, dispatch] = useReducer(dataReducer, initialState);

  const closeWs = (ws: WebSocket) => {
    ws.close();
  };

  // Gastly.getBlockByNumber(14467552).then(console.log);

  const blockCallback = (res: IProcessedBlock) => {
    blockEnd = Date.now();
    // console.log('block callbackTime Diff', blockEnd - blockStart, 'ms');
    blockStart = blockEnd;

    let callbackDate = new Date();
    let callbackTime = callbackDate.toUTCString();

    if (callbackTime !== prevPacketUTC) {
      const gasPrices = {
        fast: calcFeeCap(res.basefee, (res.priorityFees as IPriorityFees).fast),
        average: calcFeeCap(res.basefee, (res.priorityFees as IPriorityFees).average),
        slow: calcFeeCap(res.basefee, (res.priorityFees as IPriorityFees).slow),
      };

      dispatch({
        type: 'UPDATE_BLOCK_AND_GAS_PRICES',
        payload: { block: res, priorityFees: res.priorityFees, gasPrices },
      });
      dispatch({ type: 'UPDATE_BASEFEE_RECORDS', payload: res });
      dispatch({ type: 'UPDATE_TXCOUNT_RECORDS', payload: res });
      dispatch({ type: 'CALC_GAS_FEES' });
      dispatch({ type: 'CALC_FIAT_FEES' });

      document.title = `Base Fee: ${
        res.basefee.toFixed(1).split('.')[1] === '0' ? +res.basefee.toFixed(0) : +res.basefee.toFixed(1)
      } Gwei`;
      localStorage.setItem('gastly.xyz.block', JSON.stringify(res));
      localStorage.setItem('gastly.xyz.gasPrices', JSON.stringify(gasPrices));

      prevPacketUTC = callbackTime;
    } else {
      // console.log('Packet Congestion');
    }
  };

  const spotCallback = (res: ISpotResponse) => {
    disconnected = false;
    if (!sub.block && !disconnected) initBlockWs(BASE_URL);

    dispatch({ type: 'UPDATE_SPOT', payload: res });
    localStorage.setItem('gastly.xyz.spot', JSON.stringify(res));
    dispatch({ type: 'CALC_GAS_FEES' });
    dispatch({ type: 'CALC_FIAT_FEES' });
  };

  const getSpot = () => {
    Gastly.getSpot()
      .then(spotCallback)
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          console.log('spot price HTTP request failed [likely Internet]');

          if (sub.block) {
            disconnected = true;
            closeWs(sub.block);
          }
        }
      });
  };

  const getLatestBlock = () => {
    Gastly.getLatestBlock()
      .then((res) => {
        blockCallback(res);
        clearLoading();
      })
      .catch((err) => {
        console.error(err);
        console.log('Cannot make REST request. Network error (likely Internet related');
      });
  };

  const initBlockWs = (url: string) => {
    sub.block = new WebSocket(url);
    prevPacketUTC = new Date().toUTCString();

    sub.block.onopen = function () {
      // console.log(`block Websocket opened`);
    };

    sub.block.onmessage = function (msg: MessageEvent) {
      const block = JSON.parse(msg.data) as IProcessedBlock;

      if (block.basefee) {
        blockCallback(block);
      }
    };

    sub.block.onclose = function () {
      sub.block = undefined;
      // console.log(`block Websocket closed`);
      if (!disconnected) {
        initBlockWs(BASE_URL);
      }
    };

    getSpot();

    if (handlerControl.block.length < 1) {
      // console.log('init timer in handler');
      handlerControl.block[0] = setInterval(getSpot, 15000);
      // console.log('[init] length:', handlerControl.block.length);
    }

    // TODO: Once WS setup figured out, determine how to use context to store and update changes (ws diagnostics maintained in state)
  };

  const checkCachedSettings = () => {
    const fiatPreference = localStorage.getItem('gastly.xyz.fiatPreference');
    const transactionPreference = localStorage.getItem('gastly.xyz.transactionPreference');
    const speed = localStorage.getItem('gastly.xyz.speed');

    getLatestBlock();

    if (speed) {
      dispatch({ type: 'LOAD_CACHED_SPEED', payload: speed });
    } else {
      console.log('No speed preference found, loading default...');
      dispatch({ type: 'DEFAULT_SPEED' });
    }

    if (transactionPreference) {
      dispatch({ type: 'LOAD_CACHED_TRANSACTION_PREFERENCE', payload: transactionPreference });
      dispatch({ type: 'CALC_GAS_FEES' });
    } else {
      console.log('No transaction preference found, loading default...');
      dispatch({ type: 'LOAD_DEFAULT_TRANSACTION_PREFERENCE' });
      dispatch({ type: 'CALC_GAS_FEES' });
    }

    if (fiatPreference) {
      dispatch({ type: 'LOAD_CACHED_FIAT_PREFERENCE', payload: fiatPreference });
      dispatch({ type: 'CALC_FIAT_FEES' });
    } else {
      console.log('No fiat preference found, loading default...');
      dispatch({ type: 'LOAD_DEFAULT_FIAT_PREFERENCE' });
      dispatch({ type: 'CALC_FIAT_FEES' });
    }
  };

  const startService = () => {
    getLatestBlock();
    checkCachedSettings();
    getSpot();

    /* Fetches intial array of records */
    getBaseFeeRecord();
    getTransactionCountRecord();
  };

  const updateSpeedPref = (speed: string) => {
    localStorage.setItem('gastly.xyz.speed', speed);
    dispatch({ type: 'UPDATE_SPEED', payload: speed });
  };

  const updateFiatPref = (fiat: string) => {
    localStorage.setItem('gastly.xyz.fiatPreference', fiat);
    dispatch({ type: 'UPDATE_FIAT_PREF', payload: fiat });
    dispatch({ type: 'CALC_FIAT_FEES' });
  };

  const updateTransactionPref = (type: string) => {
    dispatch({ type: 'UPDATE_TRANSACTION_PREF', payload: type });
    localStorage.setItem('gastly.xyz.transactionPreference', type);
    dispatch({ type: 'CALC_GAS_FEES' });
    dispatch({ type: 'CALC_FIAT_FEES' });
  };

  const baseFeeRecordCallback = (res: IBaseFeeChartArray) => {
    dispatch({ type: 'UPDATE_BASEFEE_RECORD_ARRAY', payload: res });
    localStorage.setItem('gastly.xyz.basefee.record.array', JSON.stringify(res));
  };

  const transactionCountRecordCallback = (res: ITXCountChartArray) => {
    dispatch({ type: 'UPDATE_TXCOUNT_RECORD_ARRAY', payload: res });
    localStorage.setItem('gastly.xyz.txcount.record.array', JSON.stringify(res));
  };

  const getBaseFeeRecord = () => {
    Gastly.getBaseFeeRecords()
      .then(baseFeeRecordCallback)
      .catch((err) => {
        console.error(err);
        console.log('Cannot make REST request. Network error (likely Internet related');
      });
  };

  const getTransactionCountRecord = () => {
    Gastly.getTransactionCountRecords()
      .then(transactionCountRecordCallback)
      .catch((err) => {
        console.error(err);
        console.log('Cannot make REST request. Network error (likely Internet related');
      });
  };

  const setLoading = () => dispatch({ type: 'SET_LOADING' });
  const clearLoading = () => dispatch({ type: 'CLEAR_LOADING' });

  useEffect(() => {
    startService();
  }, []);

  return (
    <DataContext.Provider
      value={{
        gasPrices: state.gasPrices,
        ethusd: state.ethusd,
        ethzar: state.ethzar,
        ethaud: state.ethaud,
        ethbtc: state.ethbtc,
        gasFees: state.gasFees,
        fiatFees: state.fiatFees,
        speed: state.speed,
        transactionPreference: state.transactionPreference,
        fiatPreference: state.fiatPreference,
        block: state.block,
        priorityFees: state.priorityFees,
        updateSpeedPref,
        updateFiatPref,
        updateTransactionPref,
        baseFeeRecordArr: state.baseFeeRecordArr,
        txCountRecordArr: state.txCountRecordArr,
        getBaseFeeRecord,
        getTransactionCountRecord,
        setLoading,
        clearLoading,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataState;
