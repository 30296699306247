import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import ExplorerContext from './explorerContext';
import explorerReducer from './explorerReducer';
import { Gastly } from '@src/api/gastly';

const ExplorerState: React.FC = (props) => {
  const navigate = useNavigate();

  const initialState = {
    searchTerm: null,
    loading: false,
    blockDetails: null,
    error: null,
  };

  const [state, dispatch] = useReducer(explorerReducer, initialState);

  const setSearchTerm = async (term: number | string) => {
    setLoading();
    dispatch({ type: 'SET_SEARCH_TERM', payload: term });
    try {
      if (term === '') throw new Error('Invalid Block Number Supplied');
      if (isNaN(+term)) throw new Error('Invalid Block Number Supplied');
      const blockDetails = await Gastly.getBlockByNumber(+(term as string));
      dispatch({ type: 'CACHE_SEARCH_RESULT', payload: blockDetails });
      navigate(`/block/${term}`);
    } catch (err: any) {
      dispatch({ type: 'SEARCH_ERROR', payload: err.message });
    }

    clearLoading();
  };

  const setLoading = () => {
    dispatch({ type: 'SET_LOADING' });
  };
  const clearLoading = () => dispatch({ type: 'CLEAR_LOADING' });

  const clearError = () => dispatch({ type: 'CLEAR_ERROR' });

  return (
    <ExplorerContext.Provider
      value={{
        searchTerm: state.searchTerm,
        loading: state.loading,
        blockDetails: state.blockDetails,
        error: state.error,
        setSearchTerm,
        setLoading,
        clearLoading,
        clearError,
      }}
    >
      {props.children}
    </ExplorerContext.Provider>
  );
};

export default ExplorerState;
