import React from 'react';
import { ThemeProvider } from 'styled-components';

import { useSettingsState } from '@src/consumers';

const ThemeSettings: React.FC = (props) => {
  const { theme } = useSettingsState() as SettingsContextProps;

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default ThemeSettings;
