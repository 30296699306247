import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';

import SpinnerOverlay from '@components/loader/SpinnerOverlay';
import { useExplorerState } from '@src/consumers';

interface IBlockMetrics {
  block: IProcessedBlock;
}

const LoadingOverlay = styled.div<{ theme: any; loading: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: pink;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props: any) => (props.loading === 'true' ? 'visible' : 'hidden')};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-right: none;
  padding: 10px 15px;
  padding-bottom: 5px;

  .ethbtc {
    width: 40%;

    span {
      color: rgb(221, 239, 255) !important;
      background: rgb(20, 114, 158) !important;
    }
  }

  h1 {
    background: none !important;
    padding-bottom: 0;
    font-size: 30px;
    letter-spacing: 2px;
  }

  @media (${`max-width:` + '1000px'}) {
    flex-direction: column;
  }
`;

const valueUpdate = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

const ValueWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  width: 28%;
  justify-content: space-around;
  text-align: center;
  p {
    user-select: none;
    color: ${(props: IThemeProps) => props.theme.blockMetricsLabel};
    text-shadow: 0 0 2px ${(props: any) => props.theme.blockMetricsLabelTextShadow};
    letter-spacing: 0.7px;
    padding: 0 5px;
    font-size: 16px;
    margin: 5px 10px;
    font-family: 'Helvetica Neue', Roboto, Arial, 'Droid Sans', sans-serif;
  }

  @media (${`max-width:` + '1000px'}) {
    width: 60%;

    p {
      margin-top: 30px;
    }
  }
`;

const Value = styled.span`
  color: ${(props: IThemeProps) => props.theme.valueColor};
  animation: ${valueUpdate} 0.6s ease-out;
  font-family: sans-serif;
  letter-spacing: 1.8px;
  font-weight: 400;
  font-size: 16px;
  border: 2px solid ${(props: IThemeProps) => props.theme.blockMetricsBorder};
  border-radius: 7px;
  background: ${(props: IThemeProps) => props.theme.blockMetricsValueBG};
  padding: 8px 9px;
  margin-left: 2px;
  text-shadow: none;

  &.blockNumber {
    cursor: pointer;
  }
`;

const BlockMetrics = ({ block }: IBlockMetrics) => {
  const [blockNum, setBlockNum] = useState(null);
  const [clicked, setClicked] = useState(false);
  /* loading has to be string as prop is parsed as one in styled-components */
  const [loading, setLoading] = useState<string>('false');
  const { setSearchTerm, error, clearError } = useExplorerState() as ExplorerContextProps;

  const notify = (err: string) =>
    toast.error(err, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const handleBlockNumberClick = (e: any) => {
    const val = e.target.innerHTML.split(' ').join('').replace('<span>', '').replace('</span>', '');
    setClicked(true);
    setBlockNum(val);
  };

  useEffect(() => {
    if (!error) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const redirectToBlockDetails = (async () => {
        if (blockNum) {
          setLoading('true');
          await setSearchTerm(blockNum);
          return function cleanup() {
            setLoading('false');
            setBlockNum(null);
            setClicked(false);
          };
        }
      })();
    } else {
      if (clicked) {
        notify('Something Went Wrong :(');
        clearError();
        setClicked(false);
      }
    }
  }, [blockNum, error]);
  return (
    <>
      {loading === 'true' && (
        <LoadingOverlay loading={loading}>
          <SpinnerOverlay />
        </LoadingOverlay>
      )}
      <Wrapper>
        <ValueWrapper>
          <p>Block Number</p>
          <Value className='blockNumber' onClick={handleBlockNumberClick}>
            <CountUp decimals={0} end={+block.number} duration={0.6} separator=' ' />
          </Value>
        </ValueWrapper>
        <ValueWrapper>
          {block.basefee ? (
            <>
              <p>Base Fee</p>
              <Value>
                <CountUp
                  decimals={block.basefee.toFixed(1).split('.')[1] === '0' ? 0 : 1}
                  end={block.basefee}
                  duration={0.6}
                />{' '}
                Gwei
              </Value>
            </>
          ) : null}
        </ValueWrapper>
        {/* <ValueWrapper className='ethbtc'>
          <p>ETH/BTC</p>
          <Value>
            <CountUp decimals={4} end={btcSpot} duration={0.6} separator=' ' />
          </Value>
        </ValueWrapper> */}
        <ValueWrapper>
          <p>TX Count</p>
          <Value>
            <CountUp decimals={0} end={block.txCount} duration={0.6} separator=' ' />
          </Value>
        </ValueWrapper>
      </Wrapper>
      <Wrapper>
        <ValueWrapper>
          <p>Gas Used</p>
          <Value>
            <CountUp decimals={0} end={+block.gasUsed} duration={0.6} separator=' ' />
          </Value>
        </ValueWrapper>
        <ValueWrapper>
          <p>Capacity</p>
          <Value>
            <CountUp decimals={0} end={block.gasUtilizationRatio * 100} duration={0.6} separator=' ' suffix={'%'} />
          </Value>
        </ValueWrapper>
        <ValueWrapper>
          <p>Block Size</p>
          <Value>
            <CountUp decimals={0} end={block.size / 1000} duration={0.6} separator=' ' suffix={' kB'} />
          </Value>
        </ValueWrapper>
      </Wrapper>
    </>
  );
};

export default BlockMetrics;
