import styled from 'styled-components';

import Cards from './Cards';
import { Wrapper } from '../generic/Wrapper';

const WidgetWrapper = styled(Wrapper)`
  border-radius: 0;
  border: none;
  margin-top: 50px;
  /* margin-bottom: 10px; */
  width: 100%;
  padding: 30px 20px;
`;

const GastlyWidget = () => {
  return (
    <WidgetWrapper>
      <Cards />
    </WidgetWrapper>
  );
};

export default GastlyWidget;
