import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import is from 'is_js';

import DarkModeSwitch from '@components/dark-mode/DarkModeSwitch';
import { breakpoints } from '@src/theme/globalStyle';

import { useExplorerState, useSettingsState } from '@src/consumers';
import Search from '../search/Search';

import chrome from './chrome-web-store.png';
import firefox from './firefox.png';

interface IExtensionObject {
  img: any;
  link: string;
}

interface IExtensionMap {
  [key: string]: IExtensionObject;
}

const EXTENSION_MAP: IExtensionMap = {
  chrome: {
    img: chrome,
    link: 'https://chrome.google.com/webstore/detail/gastly/oajdjjcjlllhmklicblbjfmfkekeldfa',
  },
  firefox: {
    img: firefox,
    link: 'https://addons.mozilla.org/en-US/firefox/addon/gastly-ethereum-gas-tool/',
  },
};

export const NavbarWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props: any) => props.theme.wrapperBackground};
  border-bottom: 2px solid ${(props: any) => props.theme.navbarBottomBorder};

  a {
    text-decoration: none;
  }

  @media (${`min-width:` + breakpoints.laptop}) {
    height: 75px;
  }
`;

export const MenuIcon = styled.div``;

export const Title = styled.h1`
  padding: 0;
  padding-left: 20px;
  margin: 15px;
  font-family: 'Helvetica Neue', Roboto, Arial, 'Droid Sans', sans-serif;
  text-align: center;
  letter-spacing: 2px;
  font-size: 34px;
  font-weight: 300;
  color: ${(props: any) => props.theme.gastlyTitle};
`;

export const ToggleButtonWrapper = styled.div`
  display: flex;
  margin-right: 30px;
`;

export const NavRightEndContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AddExtensionWrapper = styled.div`
  margin-right: 10px;
`;

export const AddExtension = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: 'Helvetica Neue', Roboto, Arial, 'Droid Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  padding: 10px 18px;
  color: #fff;
  background-color: rgba(70, 144, 222);
  border: 1px solid rgba(70, 144, 222);
  border-radius: 8px;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out,
    box-shadow 0.15s ease-in-out;

  img {
    margin-left: 8px;
  }

  &:hover {
    color: #7e7e7e;
    background: #fff;
    border-color: #fff;
  }
`;

const Navbar = () => {
  console.log('Firefox', is.firefox());
  console.log('Chrome', is.chrome());
  const { searchTerm, setSearchTerm } = useExplorerState() as ExplorerContextProps;
  const { browser } = useSettingsState() as SettingsContextProps;

  return (
    <NavbarWrapper>
      <Link to='/'>
        <Title>Gastly</Title>
      </Link>

      <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <NavRightEndContainer>
        <AddExtensionWrapper>
          {browser && (
            <>
              <AddExtension href={EXTENSION_MAP[browser].link} target='_blank' rel='noreferrer'>
                Download the Extension
                <img src={browser === 'chrome' ? chrome : firefox} width='20' height='20' alt='' />
              </AddExtension>
            </>
          )}
        </AddExtensionWrapper>
        <ToggleButtonWrapper>
          <DarkModeSwitch />
        </ToggleButtonWrapper>
      </NavRightEndContainer>
    </NavbarWrapper>
  );
};

export default Navbar;
