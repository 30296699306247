export const SYMBOL_MAP: ISymbolMap = {
  ethusd: '$',
  ethzar: 'R',
  ethaud: '$',
};

export const BLOCK_LOCALHOST_URL = 'ws://localhost:5000/block';
export const BLOCK_PRODUCTION_URL = 'wss://api.gastly.tools/block';

export const BASE_URL = process.env.NODE_ENV === 'development' ? BLOCK_LOCALHOST_URL : BLOCK_PRODUCTION_URL;

export const feeChartTransitions = {
  show: {
    animations: {
      x: {
        from: 1,
      },
      y: {
        from: 1200,
      },
    },
  },
  hide: {
    animations: {
      x: {
        to: -200,
      },
      y: {
        to: 0,
      },
    },
  },
};

export const transactionCountChartTransitions = {
  show: {
    animations: {
      x: {
        from: 1,
      },
      y: {
        from: 1200,
      },
    },
  },
  hide: {
    animations: {
      x: {
        to: -200,
      },
      y: {
        to: 0,
      },
    },
  },
};

export const errorMessage = {
  BAD_REQUEST: 'Request failed with status code 400',
};
