import { useState, useEffect } from 'react';
import { DarkModeToggle } from 'react-dark-mode-toggle-2';

import { useSettingsState } from '@consumers/index';

const DarkModeSwitch = () => {
  const { darkMode, setTheme } = useSettingsState() as SettingsContextProps;
  const [dark, setDark] = useState(darkMode);

  useEffect(() => {
    setTheme(dark);
  }, [dark]);

  return <DarkModeToggle onChange={setDark} isDarkMode={dark} size={70} />;
};

export default DarkModeSwitch;
