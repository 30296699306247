import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  min-width: 1050px;
  background: ${(props: IThemeProps) => props.theme.wrapperBackground};
  border: 4px solid ${(props: IThemeProps) => props.theme.gastlyWrapperBorder};
  border-radius: 15px;
  padding: 50px 20px;
`;

export { Wrapper };
