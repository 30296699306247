import React, { useState } from 'react';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '@components/loader/Spinner';
import { useExplorerState } from '@src/consumers';

interface SearchProps {
  searchTerm: string | number;
  setSearchTerm: any;
}

const Form = styled.form`
  display: flex;
`;

const Label = styled.label`
  span {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  min-width: 100px;
`;

const Input = styled.input`
  border: 1px solid grey;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  height: 38px;
  width: 100%;
  padding: 2px 10px 2px 50px;
  outline: 0;
  background: ${(props: any) => props.theme.searchBackground};
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 100;
  border: none;
  border: 2px solid ${(props: any) => props.theme.searchBorderColor};
  border-right: none;
  color: ${(props: any) => props.theme.searchInputColor};

  &:hover,
  &:focus {
  }

  &::placeholder {
    color: ${(props: any) => props.theme.searchPlaceholderColor};
    font-weight: 300;
  }
`;

const Button = styled.button`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
  cursor: pointer;
  background: ${(props: any) => props.theme.searchBtn};
  border: 2px solid ${(props: any) => props.theme.searchBorderColor};

  span {
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 18px;
    color: white;
  }

  &:hover {
    transition: ease-in-out 0.3s;
    background: ${(props: any) => props.theme.speedValueSelected};
  }
`;

const Search = ({ searchTerm, setSearchTerm }: SearchProps) => {
  const { loading } = useExplorerState() as ExplorerContextProps;
  const [val, setVal] = useState(searchTerm || '');
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    let blockNum = formData.get('blockNumber') as string;
    await setSearchTerm(blockNum);
    setVal('');
  };

  return (
    <Form onSubmit={handleSubmit} role='search'>
      <Label htmlFor='block-search'>
        <span className='visually-hidden'>Search Ethereum Block</span>
      </Label>
      <InputWrapper>
        <Input
          type='text'
          id='block-search'
          placeholder='Search block number'
          name='blockNumber'
          value={val}
          onChange={(e) => setVal(e.target.value)}
        />
      </InputWrapper>
      <Button type='submit'>
        <span>{loading ? <Spinner /> : <FontAwesomeIcon icon={faSearch} />}</span>
      </Button>
    </Form>
  );
};

export default Search;
