import axios, { AxiosResponse } from 'axios';

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://api.gastly.tools';

const instance = axios.create({
  baseURL,
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
};

export const Gastly = {
  getLatestBlock: (): Promise<IProcessedBlock> => requests.get('/block'),
  getSpot: (): Promise<ISpotResponse> => requests.get('/spot'),
  getBaseFeeRecords: (): Promise<IBaseFeeChartArray> => requests.get('/basefeerecords'),
  getBlockByNumber: (block: number): Promise<IProcessedBlock> => requests.get(`/block/${block}?verbose=true`),
  getTransactionCountRecords: (): Promise<ITXCountChartArray> => requests.get('/txcountrecords'),
};
