import styled, { keyframes } from 'styled-components';

import { useDataState } from '@consumers/index';
import { SYMBOL_MAP } from '@src/constants/index';

import BlockMetrics from './BlockMetrics';
import SpeedCard from './SpeedCard';

const DashboardWrapper = styled.div`
  width: 1050px;
  display: flex;
  flex-direction: column;

  @media (${`max-width:` + '1000px'}) {
    flex-direction: column-reverse;
  }
`;

const loadingAnimation = keyframes`
  0% {
    opacity: 0.3;
    font-size: 38px;
    color: rgb(48, 160, 255);
  }
  50% {
    opacity: 0.6;
    font-size: 30px;
    letter-spacing: 2px;
    
  }

  100% {
    opacity: 0.3;
    font-size: 38px;
    color: rgb(48, 160, 255);
  }
`;

const skeletonAnimation = keyframes`
  0% {
    background: #00000012;
  }
  50% {
    background: #cdcdcd12;
    
  }

  100% {
    background: #00000012;
  }
`;

const LoadingWrapper = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  background: #00000029;
  border-radius: 25px;
  animation: ${skeletonAnimation} 1.6s forwards infinite;

  h1 {
    font-family: sans-serif;
    opacity: 0.4;
    font-size: 32px;
    color: #fff;
    font-weight: 300;
    animation: ${loadingAnimation} 1.6s forwards infinite;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-right: none;
  padding: 10px 15px;
  margin-top: 10px;
  color: white;

  @media (${`max-width:` + '1000px'}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Cards = () => {
  const { gasPrices, fiatFees, speed, block, ethbtc, priorityFees, updateSpeedPref, fiatPreference } =
    useDataState() as DataContextProps;

  if (!block) {
    return (
      <LoadingWrapper>
        <h1>Fetching Latest Block Data...</h1>
      </LoadingWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <BlockMetrics block={block} />
      <CardWrapper>
        <SpeedCard
          title='Fast'
          currencySymbol={SYMBOL_MAP[fiatPreference]}
          value='fast'
          duration='~1 Min'
          basefee={+block.basefee}
          priorityFee={priorityFees.fast}
          gasPrice={gasPrices.fast}
          fiatFee={fiatFees.fast}
          fiatPreference={fiatPreference}
          isSelected={speed === 'fast'}
          updateSpeedPref={updateSpeedPref}
        />
        <SpeedCard
          title='Average'
          currencySymbol={SYMBOL_MAP[fiatPreference]}
          value='average'
          duration='~3 Min'
          basefee={+block.basefee}
          priorityFee={priorityFees.average}
          gasPrice={gasPrices.average}
          fiatFee={fiatFees.average}
          fiatPreference={fiatPreference}
          isSelected={speed === 'average'}
          updateSpeedPref={updateSpeedPref}
        />
        <SpeedCard
          title='Slow'
          currencySymbol={SYMBOL_MAP[fiatPreference]}
          value='slow'
          duration='>10 Min'
          basefee={+block.basefee}
          priorityFee={priorityFees.slow}
          gasPrice={gasPrices.slow}
          fiatFee={fiatFees.slow}
          fiatPreference={fiatPreference}
          isSelected={speed === 'slow'}
          updateSpeedPref={updateSpeedPref}
        />
      </CardWrapper>
    </DashboardWrapper>
  );
};

export default Cards;
