import React, { useReducer } from 'react';
import is from 'is_js';

import SettingsContext from './settingsContext';
import settingsReducer from './settingsReducer';

import { darkTheme, lightTheme } from '@theme/globalStyle';
import { fetchAndParseItem } from '@src/utils/storage';

const SettingsState: React.FC = (props) => {
  const defaultT = fetchAndParseItem('gastly.xyz.theme') !== false ? fetchAndParseItem('gastly.xyz.theme') : darkTheme;
  const determineBrowser = () => {
    const isFirefox = is.firefox();
    const isChrome = is.chrome();

    if (isFirefox) return 'firefox';
    if (isChrome) return 'chrome';
    return null;
  };

  const initialState = {
    theme: defaultT,
    darkMode: defaultT.dark ?? true,
    d: defaultT.dark ?? true,
    browser: determineBrowser(),
  };

  const [state, dispatch] = useReducer(settingsReducer, initialState);

  const setTheme = (darkMode: boolean) => {
    const payload = darkMode === true ? darkTheme : lightTheme;
    localStorage.setItem('gastly.xyz.theme', JSON.stringify(payload));
    localStorage.setItem('gastly.xyz.darkMode', JSON.stringify(darkMode));
    dispatch({ type: 'SET_THEME', payload });
    dispatch({ type: 'UPDATE_MODE_STATUS', payload: darkMode });
  };

  return (
    <SettingsContext.Provider
      value={{
        theme: state.theme,
        darkMode: state.darkMode,
        d: state.d,
        browser: state.browser,
        setTheme,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsState;
