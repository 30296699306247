import { useContext } from 'react';

import DataContext from '@context/data/dataContext';
import SettingsContext from '@context/settings/settingsContext';
import ExplorerContext from '@src/context/explorer/explorerContext';

const useDataState = () => useContext(DataContext);
const useSettingsState = () => useContext(SettingsContext);
const useExplorerState = () => useContext(ExplorerContext);

export { useDataState, useSettingsState, useExplorerState };
