/**
 * Add Base Fee + Priority Fee and return as integer
 * @param {number} - basefee (e.g. 60.12334343423)
 * @param {number} - tip (e.g. 3.234234532)
 * @returns {number} - gasPrice (e.g. 63.5)
 */
function calcFeeCap(basefee: number, tip: number) {
  return +(basefee + tip).toFixed(1);
}

function calcGasFees(gasPrice: number, type: string) {
  switch (type) {
    case 'eth-send':
      return EthTransfer(gasPrice);
    case 'erc20-send':
      return ERC20Transfer(gasPrice);
    case 'uniswap-swap':
      return uniswapTrade(gasPrice);
    case 'uniswap-lp':
      return uniswapLP(gasPrice);
    default:
      return EthTransfer(gasPrice);
  }
}

function EthTransfer(gasPrice: number) {
  return gasPrice * 21000;
}

function ERC20Transfer(gasPrice: number) {
  return gasPrice * 65000;
}

function uniswapTrade(gasPrice: number) {
  return gasPrice * 200000;
}

function uniswapLP(gasPrice: number) {
  return gasPrice * 175000;
}

function ethFee(gas: number): number {
  return Math.pow(10, -9) * gas;
}

function calcFiatFees(gas: number, rate: string): number {
  return +(ethFee(gas) * +rate).toFixed(2);
}

export { calcFeeCap, calcGasFees, calcFiatFees };
