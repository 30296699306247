// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: any, action: any) => {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        theme: action.payload,
      };

    case 'UPDATE_MODE_STATUS':
      return {
        ...state,
        d: action.payload,
      };

    default:
      return state;
  }
};
