// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: any, action: any) => {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };

    case 'CACHE_SEARCH_RESULT':
      return {
        ...state,
        blockDetails: action.payload,
        searchTerm: null,
      };

    case 'SEARCH_ERROR':
      return {
        ...state,
        error: action.payload,
      };

    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      };

    case 'CLEAR_LOADING':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
