import { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from 'chart.js';

import { useDataState } from '@consumers/index';
import { useSettingsState } from '@consumers/index';
// import { transactionCountChartTransitions as transitions } from '@src/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Filler);

const TransactionCountChart = ({ width }: IChartComponentProps) => {
  const chartRef = useRef(null);
  const { txCountRecordArr } = useDataState() as DataContextProps;
  const { d } = useSettingsState() as SettingsContextProps;
  const [chartData, setChartData] = useState<any>({
    datasets: [],
  });

  const options: any = {
    responsive: true,
    // transitions,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: true,
        text: 'TX Count Per Block',
        color: d ? 'rgba(70, 144, 222)' : 'rgba(29, 93, 210, 0.9)',
        font: {
          size: 30,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          weight: 300,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Block Number',
          color: d ? '#54648d' : 'rgba(29, 93, 210, 0.9)',
          font: {
            size: 20,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            weight: d ? 300 : 400,
            lineHeight: 1.2,
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 },
        },
        grid: {
          color: d ? 'rgba(255, 255, 255, 0.15)' : 'rgba(75, 75, 75, 0.214)',
          lineWidth: 0.5,
        },
        ticks: {
          color: d ? '#a9a9a9' : 'rgba(29, 93, 210, 0.9)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'TX Count',
          color: d ? '#54648d' : 'rgba(29, 93, 210, 0.9)',
          font: {
            size: 20,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            weight: d ? 300 : 400,
            lineHeight: 1.2,
          },
          padding: { top: 0, left: 0, right: 0, bottom: 20 },
        },
        grid: {
          color: d ? 'rgba(255, 255, 255, 0.15)' : 'rgba(43, 43, 43, 0.149)',
          lineWidth: 0.5,
        },
        ticks: {
          callback: function (value: any) {
            return value;
          },
          color: d ? '#a9a9a9' : 'rgba(29, 93, 210, 0.9)',
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current as ChartJS | null;

    if (!chart) {
      return;
    }

    let gradient = chart.ctx.createLinearGradient(0, 0, 0, 1000);
    gradient.addColorStop(0, 'rgba(58, 123, 213, 0.7)');
    gradient.addColorStop(1, 'rgba(87, 90, 115, 0.117)');

    setChartData({
      labels: txCountRecordArr.map((x) => x.blockNumber),
      datasets: [
        {
          label: 'TX Count',
          data: txCountRecordArr.map((x) => x.txCount),
          backgroundColor: gradient,
        },
      ],
    });
  }, [d, txCountRecordArr]);

  return (
    <div style={{ width: width, backgroundColor: d ? '#09223C' : 'rgb(83 184 249)', marginTop: '30px' }}>
      <Bar ref={chartRef} options={options} data={chartData} />
    </div>
  );
};

export default TransactionCountChart;
