import { createGlobalStyle } from 'styled-components';

export const darkTheme = {
  dark: true,
  gastlyTitle: 'rgba(70, 144, 222)',
  body: '#08223c',
  wrapperBackground: '#1e5474',
  gastlyWrapperBorder: '#123',

  // Navbar
  navbarBottomBorder: 'rgb(64 113 136)',
  searchBackground: '#1e5474',
  searchBorderColor: 'rgb(64 113 136)',
  searchPlaceholderColor: '#f4f4f4',
  searchInputColor: '#66e2ff',
  searchBtn: 'rgb(13, 60, 88)',

  // Block Metrics
  blockMetricsLabel: '#88c8ff',
  blockMetricsLabelTextShadow: '#737373',
  blockMetricsBorder: 'rgb(13, 60, 88)',
  blockMetricsValueBG: 'rgb(13, 60, 88)',
  valueColor: '#66e2ff',
  // Speed Card
  feeLabel: '#fff',
  feeValue: '#66e2ff',
  speedCardBG: '#08223c',
  selectedSpeedCardBG: 'rgb(23, 105, 139)',
  speedValueSelected: 'rgb(48, 160, 255)',
  speedValueDefault: '#ffffffbf',
  speedCardSelected: 'rgb(48, 160, 225)',
  speedCardDefault: 'rgb(18, 81, 117)',
  speedCardFiatFeeDefault: '#bbbbbb',
  speedCardFiatFeeSelected: '#66e2ff',

  // Block Analytics Page
  blockAnalytics: {
    metricWrapper: {
      labelBG: '#0f0b2f',
      labelValue: '#fff',
      amountBG: '#fff',
      amountValue: '#111',
    },
  },
};

export const lightTheme = {
  dark: false,
  gastlyTitle: 'rgb(0, 141, 225)',
  body: 'rgb(83 184 249)',
  wrapperBackground: 'rgb(142 211 255)',
  gastlyWrapperBorder: 'rgb(70 163 217)',

  // Navbar
  navbarBottomBorder: 'rgb(124, 195, 255)',
  searchBackground: '#9fd2ff',
  searchBorderColor: '#eee',
  searchPlaceholderColor: '#ffffffa4',
  searchInputColor: '#1a96d7',
  searchBtn: 'rgb(101 166 215)',

  // Block Metrics
  blockMetricsLabel: 'rgb(177, 226, 255)',
  blockMetricsLabelTextShadow: 'rgb(0, 2, 4)',
  blockMetricsBorder: 'rgb(170 235 255)',
  blockMetricsValueBG: 'rgb(101 166 215)',
  valueColor: 'rgb(255,255,255)',

  // Speed Card
  feeLabel: 'rgb(177, 226, 255)',
  feeValue: 'rgb(255,255,255)',
  speedCardBG: 'rgb(101 166 215)',
  selectedSpeedCardBG: 'rgb(0 113 167)',
  speedValueSelected: 'rgb(70 170 255)',
  speedValueDefault: 'rgb(255,255,255)',
  speedCardSelected: 'rgb(0,161,255)',
  speedCardDefault: 'rgb(170 235 255)',
  speedCardFiatFeeDefault: 'rgb(255,255,255)',
  speedCardFiatFeeSelected: '#66e2ff',

  // Block Analytics Page
  blockAnalytics: {
    metricWrapper: {
      labelBG: '#0081a5',
      labelValue: '#fff',
      amountBG: '#fff',
      amountValue: '#111',
    },
  },
};

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }


  html {

    /* issue with margin on navbar */
    @media (${`max-width:` + '1000px'}) {
      width: max-content;
    }
  }
  
  body {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background: ${(props: IThemeProps) => props.theme.body};
    transition: all 0.2s linear;
  }
`;

export const breakpoints = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${breakpoints.mobileS})`,
  mobileM: `(min-width: ${breakpoints.mobileM})`,
  mobileL: `(min-width: ${breakpoints.mobileL})`,
  tablet: `(min-width: ${breakpoints.tablet})`,
  laptop: `(min-width: ${breakpoints.laptop})`,
  laptopL: `(min-width: ${breakpoints.laptopL})`,
  desktop: `(min-width: ${breakpoints.desktop})`,
  desktopL: `(min-width: ${breakpoints.desktop})`,
};
