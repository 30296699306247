import { useEffect } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GastlyWidget from '@components/gas-widget/GastlyWidget';
import FeeChart from '@src/components/charts/FeeChart';
import TransactionCountChart from '@src/components/charts/TransactionCountChart';
import EIP1559Pie from '@src/components/charts/EIP1559Pie';
import { useExplorerState } from '@src/consumers';

const WIDTH = '950px';

const AppWrapper = styled.div`
  color: ${(props: IThemeProps) => props.theme.gastlyTitle};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;

  @media (${`max-width:` + '1800px'}) {
    flex-direction: column;
    width: 90%;
  }
`;

const Home = () => {
  const { error, clearError } = useExplorerState() as ExplorerContextProps;

  const notify = (err: string) =>
    toast.error(err, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  useEffect(() => {
    if (error) {
      notify(error);
      clearError();
    }
  }, [error]);
  return (
    <AppWrapper>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GastlyWidget />
      <ChartSectionWrapper>
        <FeeChart width={WIDTH} />
        <TransactionCountChart width={WIDTH} />
      </ChartSectionWrapper>
      <EIP1559Pie width={'540px'} />
    </AppWrapper>
  );
};

export default Home;
