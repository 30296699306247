import { calcGasFees, calcFiatFees } from '@utils/fees';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: any, action: any) => {
  switch (action.type) {
    case 'LOAD_DEFAULT_SPEED':
      return {
        ...state,
        speed: 'fast',
      };

    case 'LOAD_DEFAULT_TRANSACTION_PREFERENCE':
      return {
        ...state,
        transactionPreference: 'eth-transfer',
      };

    case 'LOAD_DEFAULT_FIAT_PREFERENCE':
      return {
        ...state,
        fiatPreference: 'ethusd',
      };

    case 'LOAD_CACHED_SPEED':
      return {
        ...state,
        speed: action.payload,
      };

    case 'LOAD_CACHED_TRANSACTION_PREFERENCE':
      return {
        ...state,
        transactionPreference: action.payload,
      };

    case 'LOAD_CACHED_FIAT_PREFERENCE':
      return {
        ...state,
        fiatPreference: action.payload,
      };

    case 'UPDATE_SPOT':
      return {
        ...state,
        ethusd: action.payload.ethusd,
        ethzar: action.payload.ethzar,
        ethaud: action.payload.ethaud,
        ethbtc: action.payload.ethbtc,
      };

    case 'UPDATE_BLOCK_AND_GAS_PRICES':
    case 'LOAD_CACHED_BLOCK':
      return {
        ...state,
        block: action.payload.block,
        priorityFees: {
          fast: action.payload.priorityFees.fast,
          average: action.payload.priorityFees.average,
          slow: action.payload.priorityFees.slow,
        },
        gasPrices: action.payload.gasPrices,
      };

    // NB! -> Called by websockets stream callback (IStandardizedBlock)
    case 'UPDATE_BASEFEE_RECORDS':
      // eslint-disable-next-line no-case-declarations
      let updated = [...state.baseFeeRecordArr];
      updated.splice(0, 1);
      updated.push({
        basefee: +action.payload.basefee.toFixed(1),
        blockNumber: action.payload.number,
        timestamp: action.payload.timestamp,
      });
      return {
        ...state,
        baseFeeRecordArr: updated,
      };

    // NB! -> Called by websockets stream callback (IStandardizedBlock)
    case 'UPDATE_TXCOUNT_RECORDS':
      // eslint-disable-next-line no-case-declarations
      let u = [...state.txCountRecordArr];
      u.splice(0, 1);
      u.push({
        blockNumber: action.payload.number,
        txCount: action.payload.txCount,
      });
      return {
        ...state,
        txCountRecordArr: u,
      };

    case 'UPDATE_SPEED':
      return {
        ...state,
        speed: action.payload,
      };

    case 'UPDATE_FIAT_PREF':
      return {
        ...state,
        fiatPreference: action.payload,
      };

    case 'UPDATE_TRANSACTION_PREF':
      return {
        ...state,
        transactionPreference: action.payload,
      };

    case 'CALC_GAS_FEES':
      return {
        ...state,
        gasFees: {
          fast: calcGasFees(state.gasPrices.fast, state.transactionPreference),
          average: calcGasFees(state.gasPrices.average, state.transactionPreference),
          slow: calcGasFees(state.gasPrices.slow, state.transactionPreference),
        },
      };

    case 'CALC_FIAT_FEES':
      return {
        ...state,
        fiatFees: {
          fast: calcFiatFees(state.gasFees.fast, state[state.fiatPreference]),
          average: calcFiatFees(state.gasFees.average, state[state.fiatPreference]),
          slow: calcFiatFees(state.gasFees.slow, state[state.fiatPreference]),
        },
      };

    case 'UPDATE_BASEFEE_RECORD_ARRAY':
      return {
        ...state,
        baseFeeRecordArr: action.payload,
      };

    case 'UPDATE_TXCOUNT_RECORD_ARRAY':
      return {
        ...state,
        txCountRecordArr: action.payload,
      };

    default:
      return state;
  }
};
