import { Routes, Route } from 'react-router-dom';

import { GlobalStyle } from '@theme/globalStyle';

import DataState from '@context/data/DataState';
import SettingsState from '@context/settings/SettingsState';

import ThemeSettings from '@src/components/theme-wrapper/ThemeSettings';
import Navbar from '@components/navbar/Navbar';
import ExplorerState from '@src/context/explorer/ExplorerState';
import Home from '@src/pages/Home';
import BlockAnalyticsPage from '@src/pages/BlockAnalyticsPage';
import Fallback from '@src/components/error/Fallback';

function App() {
  return (
    <>
      <SettingsState>
        <DataState>
          <ExplorerState>
            <ThemeSettings>
              <GlobalStyle />
              <Navbar />
              <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/block/:number' element={<BlockAnalyticsPage />}></Route>
                <Route path='/fallback' element={<Fallback />}></Route>
              </Routes>
            </ThemeSettings>
          </ExplorerState>
        </DataState>
      </SettingsState>
    </>
  );
}

export default App;
