import React from 'react';
import styled, { keyframes } from 'styled-components';
import CountUp from 'react-countup';

const valueUpdate = keyframes`
  from {
    opacity: 0.4;
    color: rgb(48, 160, 255);
  }
  to {
    opacity: 1;
    color: white;
  }
`;

interface ISpeedCard {
  basefee: number;
  priorityFee: number;
  gasPrice: number;
  fiatFee: number;
  value: string;
  title: string;
  currencySymbol: string;
  duration: string;
  fiatPreference: string;
  isSelected: boolean;
  updateSpeedPref: (speed: string) => void;
}

interface IWrapperProps {
  selected: boolean;
}

interface IHeadProps {
  selected: boolean;
}

interface IMaxFeeCap {
  maxFeeCap: number;
  selected: boolean;
}

interface IPriceTimeWrapperProps {
  gasPrice: number;
  selected: boolean;
}

const Wrapper = styled.div<{ theme: any; selected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 28%;
  background: ${(props: any) => (props.selected ? props.theme.selectedSpeedCardBG : props.theme.speedCardBG)};
  border-radius: 15px;
  border: ${(props: any) =>
    props.selected ? `4px solid ${props.theme.speedValueSelected}` : `2px solid ${props.theme.speedCardDefault}`};
  cursor: pointer;

  @media (${`max-width:` + '1000px'}) {
    width: 60%;
    margin-bottom: 20px;
  }
`;

const Head = styled.div<{ theme: any; selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 11px;
    padding-top: 8px;
  }

  h3 {
    user-select: none;
    font-family: 'Helvetica Neue', Roboto, Arial, 'Droid Sans', sans-serif;
    text-align: center;
    letter-spacing: 2px;
    font-size: 24px;
    font-weight: ${(props: IHeadProps) => (props.selected ? '400' : '300')};
    color: ${(props: any) => (props.selected ? props.theme.speedValueSelected : props.theme.speedValueDefault)};
    text-shadow: -1px 0px #585858;
    line-height: 1.1;
    width: 60%;
    margin: 0;
    padding-top: 14px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    user-select: none;
    font-family: sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    color: ${(props: any) => (props.selected ? props.theme.speedValueSelected : props.theme.speedValueDefault)};
    font-size: ${(props: IMaxFeeCap) => (props.maxFeeCap < 999 ? '50px' : '44px')};

    border-radius: 6px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 8px;
    min-width: 40px;
  }
`;

const FeeBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &:first-of-type {
    padding-top: 5px;
    padding-bottom: 2.5px;
    margin-bottom: 5px;
  }

  &:last-of-type {
    margin-bottom: 2px;
  }

  p,
  span {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.1px;
    margin: 0;
    color: ${(props: any) => props.theme.feeLabel};
  }

  span {
    color: ${(props: any) => props.theme.feeValue};
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.6px;
  }
`;

const PriceTimeWrapper = styled.div<{ theme: any; selected: boolean; gasPrice: number }>`
  width: 75%;
  border-top: 1.5px solid #1a96d7;
  margin: 10px auto;
  margin-bottom: 0;
  padding: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  p.duration {
    margin-top: 14px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.6px;
    color: ${(props: any) => props.theme.feeLabel};
  }

  p.separator {
    margin-top: 14px;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 1.3rem;
    text-align: left;
    color: #1a96d7;
  }

  p.fee-total {
    margin-top: 14px;
    font-family: sans-serif;
    font-weight: 400;
    font-size: ${(props: IPriceTimeWrapperProps) => (props.gasPrice < 999 ? '16px' : '14px')};
    text-align: left;
    color: ${(props: any) =>
      props.selected ? props.theme.speedCardFiatFeeSelected : props.theme.speedCardFiatFeeDefault};
    letter-spacing: 1.8px;
    animation: ${valueUpdate} 1s ease-out;
  }
`;

const SpeedCard = ({
  basefee,
  priorityFee,
  gasPrice,
  fiatFee,
  isSelected,
  value,
  updateSpeedPref,
  title,
  currencySymbol,
  duration,
}: ISpeedCard) => {
  function handleSpeedChange(val: string) {
    updateSpeedPref(val);
  }

  return (
    <Wrapper onClick={() => handleSpeedChange(value as string)} selected={isSelected}>
      <Head selected={isSelected}>
        <h3>{title}</h3>
      </Head>
      <Content selected={isSelected} maxFeeCap={gasPrice}>
        <h2>{gasPrice}</h2>
      </Content>
      <FeeBody>
        <FeeRow>
          <p>Base Fee:</p>
          <CountUp
            decimals={basefee.toFixed(1).split('.')[1] === '0' ? 0 : 1}
            end={basefee}
            suffix={' Gwei'}
            duration={1}
          />
        </FeeRow>
        <FeeRow>
          <p>Priority Fee:</p>
          <CountUp
            decimals={priorityFee.toFixed(1).split('.')[1] === '0' ? 0 : 1}
            end={priorityFee}
            suffix={' Gwei'}
            duration={1}
          />
        </FeeRow>
      </FeeBody>
      <PriceTimeWrapper gasPrice={gasPrice} selected={isSelected}>
        <p className='duration'>{duration}</p>
        <p className='separator'> | </p>
        <p className='fee-total'>
          <CountUp prefix={currencySymbol} decimals={2} end={fiatFee} duration={1} />
        </p>
      </PriceTimeWrapper>
    </Wrapper>
  );
};

export default SpeedCard;
